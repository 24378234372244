import { collapseAllServices, loadServiceLong, loadServiceShort } from "./services.js";
import { fadeOutElement, fadeInElement } from "./visuals.js";

export function setSwiper() {
    initAboutSwiper();
    initGallerySwiper();
    initServicesSwiper();
}

function updateSwiper(swiperEl) {
    const swiperObj = swiperEl.swiper;
    swiperObj.updateSize();
    swiperObj.updateSlides();
}


function initAboutSwiper() {
    const aboutSwiper = new Swiper('.home__swiper', {
        pagination: {
          el: '.home__swiper-pagination'
        },
        navigation: {
          nextEl: '.home__swiper-button-next',
          prevEl: '.home__swiper-button-prev'
        },
        slidesPerView: 1
      });


    let timeStamp = new Date().getTime();
    window.addEventListener('resize', () => {        
        if (new Date().getTime()/1000 - timeStamp/1000 >= 1) { 
            timeStamp = new Date().getTime();               
            setTimeout(() => {
                    updateSwiper(document.querySelector('.home__swiper'));      
            }, 1000)                            
        }
    })



    // let homeSwiperButtons = document.querySelectorAll('.home__swiper-button');
    // homeSwiperButtons.forEach((button) => {
    //     button.addEventListener('click', () => {
    //         setTimeout(() => {
    //             checkPagination();
    //         }, 0)        
    //     })
    // })

    // function checkPagination() {
    //     let isNextButtonEnabled = aboutSwiper.slides.length - 1 > aboutSwiper.activeIndex;
    //     if (isNextButtonEnabled) {
    //         document.querySelector('.home__swiper-button-next').classList.remove('swiper-button-disabled');
    //     } else {
    //         document.querySelector('.home__swiper-button-next').classList.add('swiper-button-disabled');
    //     }

    //     let isPrevButtonEnabled = null;
    //     if (aboutSwiper.activeIndex == 0) { isPrevButtonEnabled = false;} 
    //     else { isPrevButtonEnabled = true;};
    //     if (isPrevButtonEnabled) {
    //         document.querySelector('.home__swiper-button-prev').classList.remove('swiper-button-disabled');
    //     } else {
    //         document.querySelector('.home__swiper-button-prev').classList.add('swiper-button-disabled');
    //     }
    // }
}

function initGallerySwiper() {
    let params = setGallerySwiperParams(window.innerWidth, window.innerHeight);
    let gallerySwiper = new Swiper(".gallery__swiper", params);

    window.addEventListener('resize', function() {
        let newParams = setGallerySwiperParams(window.innerWidth, window.innerHeight);
        if (params != newParams) {
            params = newParams;
            gallerySwiper.destroy();
            gallerySwiper = new Swiper(".gallery__swiper", params);
        }
    });

    function setGallerySwiperParams(windowWidth, windowHeight) {
        let params = {
            effect: 'coverflow',
            loop: true,
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 3,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            coverflowEffect: {
                rotate: 30,
                stretch: 0,
                depth: 200,
                modifier: 1,
                slideShadows: true
            }
            
        }
        if (windowWidth <= 450 && windowHeight <= 600) {
            params.effect = 'cards';
            params.slidesPerView = 2;
        
        } else if (windowWidth <= 450 && windowHeight > 600) {
            params.effect = 'cards';
            params.slidesPerView = 1;
        } else if (windowWidth > 450 && windowWidth <= 600 && windowHeight > 600) {
            params.effect = 'coverflow';
            params.slidesPerView = 2;
            params.coverflowEffect.rotate = 30;
            params.coverflowEffect.depth = 200;        
        } else if (windowWidth > 600 && windowHeight > 500 && windowHeight <= 700) {
            params.effect = 'coverflow';
            params.slidesPerView = 4;
            params.coverflowEffect.rotate = 30;
            params.coverflowEffect.depth = 200;   
        } else if (windowWidth <= 800 && windowHeight > 500) {
            params.effect = 'coverflow';
            params.slidesPerView = 3;
            params.coverflowEffect.rotate = 30;
            params.coverflowEffect.depth = 300;      
        } else if (windowWidth > 800 && windowHeight <= 500) {
            params.effect = 'coverflow';
            params.slidesPerView = 4;
            params.coverflowEffect.rotate = 15;
            params.coverflowEffect.depth = 300; 
        }
        return params;
    }
}

function initServicesSwiper() {
    let params = {
        slidesPerView: 'auto',
        spaceBetween: 0,
        freeMode: false,
        loop: false,
        centeredSlides: false,
        autoplay: true,
        // loopPreventsSliding: true,
        navigation: {
            nextEl: ".services__swiper-button-next",
            prevEl: ".services__swiper-button-prev",
        },  
      };

    let servicesSwiperObj = new Swiper(".services__swiper", params);
    let servicesSwiperEl = document.querySelector('.services__swiper');
    servicesSwiperObj.autoplay.disableOnInteraction = false;
    
    let updateInterval = setInterval(() => {
        updateSwiper(servicesSwiperEl);
    }, 1000)

    let slides = servicesSwiperEl.querySelectorAll('.swiper-slide');     
    slides.forEach(slide => {
        slide.addEventListener('click', () => {
            setTimeout(() => {
                disableSlides(slides);
                disableServicesSwiperButtons();           
            }, 10)
            let index = servicesSwiperObj.clickedIndex;
            // Focus/center the clicked slide
            if (slide.classList.contains('service__expanded')) {  
                disableLastThreeSlides();
                
                updateSwiper(servicesSwiperEl);
                slides.forEach((s) => {
                    if (s != slide) {
                        s.style.opacity = 1;
                    }
                })
                loadServiceShort(slide);
                //servicesSwiperObj.centeredSlides = false;
                // servicesSwiperObj.slidesPerView = 4;
                // servicesSwiperObj.centeredSlides = false;      
                servicesSwiperObj.autoplay.start();    
                let slideInterval = setInterval(() => {
                    servicesSwiperObj.slideTo(index, 1500, true); 
                }, 200)
                setTimeout(() => {
                    updateSwiper(servicesSwiperEl);
                    setTimeout(() => {
                        clearInterval(slideInterval);
                        updateSwiper(servicesSwiperEl); 
                        enableSlides(slides);
                        enableServicesSwiperButtons();                        
                    }, 2000)     
                }, 500);   
                updateSwiper(servicesSwiperEl);
    
            } else {                               
                servicesSwiperObj.autoplay.pause();    
                for (let i = slides.length - 1; i >= slides.length - 3; i--) {
                    slides[i].classList.remove('hidden');              
                }
                updateSwiper(servicesSwiperEl);
    
                slides.forEach((s) => {
                    if (s != slide) {
                    s.style.opacity = 0;
                    }
                })
    
                loadServiceLong(slide);   
                setTimeout(() => {
                    enableSlides(slides);
                    enableServicesSwiperButtons();           
                }, 1500)
                    servicesSwiperObj.slideTo(index, 1500, true); 
                    servicesSwiperObj.allowSlideNext = false;
                    servicesSwiperObj.allowSlidePrev = false;
            }                               
        });               
    });

    let servicesSwiperButtons = document.querySelectorAll('.services__swiper-button');
    servicesSwiperButtons.forEach((button) => {
        button.addEventListener('click', () => {
            triggerClickEvent();
        })
    })



    const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
            // The entry object contains information about the observed element's size changes
            const newWidth = entry.contentRect.width;
            const newHeight = entry.contentRect.height;
            triggerClickEvent();
        }
    });

    // Start observing the target element
    resizeObserver.observe(servicesSwiperEl);

    let timeStamp = new Date().getTime();
    window.addEventListener('resize', () => {        
        if (new Date().getTime()/1000 - timeStamp/1000 >= 1) {                
                setTimeout(() => {
                
                updateSwiper(document.querySelector('.services__swiper'));
                // setTimeout(() => {
                //     updateSwiper(document.querySelector('.services__swiper'));
                //     setTimeout(() => {
                //         updateSwiper(document.querySelector('.services__swiper'));
                //     }, 1000);
                //     //servicesSwiperObj.slideTo(index-1, 1500, true); 
                // }, 1000)                
            }, 1000)      
            timeStamp = new Date().getTime();  
        }
    })

    function disableLastThreeSlides() {
        const slides = servicesSwiperEl.querySelectorAll('.swiper-slide');
        for (let i = slides.length - 1; i >= slides.length - 3; i--) {
            slides[i].classList.add('hidden');
        }
    }

    function triggerClickEvent() {
        // Get the active slide index
        if (servicesSwiperEl.querySelector('.service__expanded')) {
            servicesSwiperEl.querySelector('.service__expanded').click();
        }
    }
    }     

    function disableSlides(slides) {
        slides.forEach((slide) => {
            slide.classList.add('disabled');
        })
    }

    function enableSlides(slides) {
            slides.forEach((slide) => {
                slide.classList.remove('disabled');
            })
    }

    function disableServicesSwiperButtons() {
        let buttonsEls = document.querySelectorAll('.services__swiper-button');
        buttonsEls.forEach((button) => {
            button.classList.add('disabled');
        })
    }

    
    function enableServicesSwiperButtons() {
        let buttonsEls = document.querySelectorAll('.services__swiper-button');
        buttonsEls.forEach((button) => {
            button.classList.remove('disabled');
        })
    }