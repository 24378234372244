import { fadeInElement, fadeOutElement } from "./visuals.js";

// import * as images from 'url:../img/news/*.jpg';
// import news from '../content/news.json';



export function setNews() {
    if (news.length == 0) {
        document.getElementById('news').style.display = 'none';
    } else {
        // printNews();
        checkOverflow();
        setActiveCards();
    }
}

// async function loadNews() {
//     const res = await fetch('./assets/content/news.json');
//     return res.json();
// }

// async function initialize() {
//     let data = await loadNews();
//     return data;
// }




function printNews() {
    const newsList = document.getElementById('news__content');

    news.forEach(el => {
        const newElement = document.createElement('article');
        newElement.classList.add('news__card');

        let buttonsHTML = '<div class="news__card-buttons buttons">';
        if (el.buttons.length > 0) {
            buttonsHTML += `            
                ${el.buttons.map(button => `
                <button class="news__card-btn btn btn__small" onclick="window.open('${button.link}','_blank')"' target="_blank">${button.name}</button>
                `).join('')}            
            `;
        }
        buttonsHTML += '</div>';

        let contentHTML = '<div class="news__card-content">';
        if (el.content.length > 0) {
            el.content.forEach(contentEl => {
                switch (contentEl.name) {
                    case 'paragraph':
                        contentHTML += `
                        <p class="news__card-paragraph text">
                            ${contentEl.value}
                        </p>
                        `
                        break;
                    case 'distinct':
                        contentHTML += `
                        <span class="news__card-distinct-text subtitle">${contentEl.value}</span>
                        `;
                        break;
                    case 'list':
                        contentHTML += '<ul class="news__card-list">';
                        contentEl.value.forEach(li => {
                        contentHTML += `                                      
                        <li class="news__card-list-item">
                            <img class="news__card-list-icon" src="./assets/img/paw.svg" alt="paw">
                            <p class="news__card-list-desc subtitle">${li}</p>
                        </li>
                        `
                        })

                        contentHTML += '</ul>';
                        break;
                }
            })            
        }
        contentHTML += '</div>';

        newElement.innerHTML = `
                    <div class="news__card-cover">
                        <img src="${images[el.imageName]}" alt="${el.imageName}" class="news__card-img">
                    </div>
                    <div class="news__card-data">
                        <p class="news__card-date subtitle">
                            ${el.date}  
                        </p>
                        <h3 class="news__card-title title">
                            ${el.title}
                        </h3>   
                        <p class="news__card-description text">
                            ${el.text}
                            <span class="text__overflowed invisible">(.    .    .)</span>                  
                        </p>
                        ${contentHTML}
                        <span class="news__card-read-more text"> 
                            <span class="news__card-arrow text">></span>
                            <span class="news__card-arrow text">></span>
                            czytaj więcej
                            <span class="news__card-arrow text"><</span> 
                            <span class="news__card-arrow text"><</span> 
                        </span>
                    </div>
                    ${buttonsHTML}
        `

        newsList.appendChild(newElement);
        // console.log(newElement.outerHTML);
        checkOverflow();
    });
}


function checkOverflow() {
    const descriptionEls = document.querySelectorAll(".news__card-description");
    descriptionEls.forEach((el) => {
        if (el.scrollHeight > el.clientHeight) {
            el.querySelector('.text__overflowed').classList.remove('invisible');
          } else {
            el.querySelector('.text__overflowed').classList.add('invisible');
          }
    })
}

let timeStamp = new Date().getTime();
function setActiveCards() {
    const newsCardEls = document.querySelectorAll('.news__card');
    newsCardEls.forEach(el => {
        el.addEventListener('click', () => {
            if (new Date().getTime()/1000 - timeStamp/1000 > 1) {   
                timeStamp = new Date().getTime();
                if (!el.classList.contains('active')) {
                    newsCardEls.forEach(element => {
                        if (element.classList.contains('active')) {
                            closeNewsCard(element);                            
                        }
                    })
                    setTimeout(() => {
                        openNewsCard(el);    
                        setTimeout(() => {
                            newsCardEls.forEach(element => {
                                if (el != element) {
                                 element.classList.add('inactive');
                                }
                            })
                        }, 300)
                    }, 300)                                           

                } else {
                    closeNewsCard(el);    
                    setTimeout(() => {
                        newsCardEls.forEach(element => {
                            element.classList.remove('inactive');
                        })   
                    }, 300)  
                }
                setTimeout(() => {
                    checkOverflow();
                }, 900);
            }  
        })
    })
}

function closeNewsCard(card) {
    const readMoreEl = card.querySelector('.news__card-read-more');
    const buttonsEl = card.querySelector('.news__card-buttons');
    const contentEl = card.querySelector('.news__card-content');
    const coverEl = card.querySelector('.news__card-cover');
    const dataEl = card.querySelector('.news__card-data');

        fadeOutElement(dataEl, 300);
        if (buttonsEl) { fadeOutElement(buttonsEl, 300); }
        fadeOutElement(contentEl, 300);

        setTimeout(() => {
            card.classList.remove('active');                
            setTimeout(() => {        
                coverEl.classList.remove('news__card-cover-hidden');                
                fadeInElement(coverEl);    
                fadeInElement(dataEl);
                fadeInElement(readMoreEl);             
            }, 10)
        }, 300)
}

function openNewsCard(card) {
    const readMoreEl = card.querySelector('.news__card-read-more');
    const buttonsEl = card.querySelector('.news__card-buttons');    
    const contentEl = card.querySelector('.news__card-content');
    const coverEl = card.querySelector('.news__card-cover');
    const dataEl = card.querySelector('.news__card-data');
    
    fadeOutElement(dataEl, 300);
    fadeOutElement(coverEl, 300);
    coverEl.classList.add('news__card-cover-hidden');

    fadeOutElement(readMoreEl, 300);   
    
    setTimeout(() => {        
        card.classList.add('active');      
        if (buttonsEl) { buttonsEl.style.opacity = 0; }

        setTimeout(() => {
            setTimeout(() => {
                fadeInElement(dataEl, 1000);
                fadeInElement(contentEl, 1500);
                if (buttonsEl) {buttonsEl.style.opacity = 1;
                fadeInElement(buttonsEl, 2000);}
            }, 10)
        }, 500);
    }, 300)
}