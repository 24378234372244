// Import the core
import { library, dom } from '@fortawesome/fontawesome-svg-core'

// Import solid icons
import { 
    faBars,
    faHouse,
    faSquare,
    faLocationArrow,
    faMoon,
    faTimesCircle,
    faUserTie,
    faLocationDot,
    faPhone,
    faEnvelope,
    faCoins,
    faSun,
    faCircleInfo,
    faXmark
} from '@fortawesome/free-solid-svg-icons'

// Import brand icons
import { 
    faFacebookF,
    faInstagram 
} from '@fortawesome/free-brands-svg-icons'


export function setIcons() {
    // Add all icons to the library
    library.add(
        // Solid icons
        faBars,
        faHouse,
        faSquare,
        faLocationArrow,
        faMoon,
        faTimesCircle,
        faUserTie,
        faLocationDot,
        faPhone,
        faEnvelope,
        faCoins,
        // Brand icons
        faFacebookF,
        faInstagram,
        faSun,
        faCircleInfo,
        faXmark
    )

    // Watch and transform icons in the DOM
    dom.watch()
}