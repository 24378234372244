const blurryBackgroundEl = document.querySelector('.blurry__background');

export function showPopup(popupEl) {
    blurryBackgroundEl.classList.remove('fadingOut');
    blurryBackgroundEl.classList.add('fadingIn');
    blurryBackgroundEl.style.display = "block";
    popupEl.classList.remove('scalingDownAbs');
    popupEl.classList.add('scalingUpAbs');
    popupEl.style.display = "block";
}

export function setPopup(popupEl) {
    let closePopupEl = popupEl.querySelector('.btn__close-popup');  
    closePopupEl.addEventListener('click', () => {
        closePopup(popupEl);
    })

    popupEl.addEventListener('click', () => {
        closePopup(popupEl);
    })
}

function closePopup(popupEl) {
    popupEl.classList.remove('scalingUpAbs');
    popupEl.classList.add('scalingDownAbs');
    

    blurryBackgroundEl.classList.remove('fadingIn');
    blurryBackgroundEl.classList.add('fadingOut');
    setTimeout(() => {
        popupEl.style.display = "none";
        setTimeout(() => {
            blurryBackgroundEl.style.display = "none";
        }, 100)        
        
    }, 400);
}