// Keep your original imports
import * as images from 'url:../img/about/timeline/*-min.jpg';
import * as imagesMax from 'url:../img/about/timeline/*-max.jpg';
import timelineData from '../content/timeline.json';

import { showPopup, setPopup } from "./popup.js";


const aboutBtnEl = document.getElementById('home__btn_more');
let timelineImgEls = document.querySelectorAll('.timeline_item-left-img');
const imagePopupEl = document.getElementById('image__popup');


export function setAbout() {
    setPopup(imagePopupEl);
    setTimelineData();
    timelineImgEls = document.querySelectorAll('.timeline_item-left-img');
    setTimelineImages();
}

const imageMap = Object.keys(images).reduce((map, key) => {
    const maxKey = key.replace('-min.jpg', '-max.jpg');
    if (imagesMax[maxKey]) {
        map[key] = {
            min: images[key],
            max: imagesMax[maxKey]
        };
    }
    return map;
}, {});

async function swapImage(timelineImgEl) {
    const imageKey = timelineImgEl.dataset.imageKey;
    const webpUrl = imagesMax[imageKey].replace('.jpg', '.webp');
    
    // Create new picture element for the popup
    const pictureEl = document.createElement('picture');
    
    // Create WebP source
    const sourceEl = document.createElement('source');
    sourceEl.srcset = webpUrl;
    sourceEl.type = 'image/webp';
    
    // Create img element
    const imgEl = document.createElement('img');
    imgEl.src = imagesMax[imageKey];
    imgEl.alt = timelineImgEl.alt;
    
    // Assemble the picture element
    pictureEl.appendChild(sourceEl);
    pictureEl.appendChild(imgEl);
    
    // Clear existing content
    const oldContent = imagePopupEl.querySelector('picture, img');
    if (oldContent) {
        imagePopupEl.removeChild(oldContent);
    }
    
    // Add new picture element to popup
    imagePopupEl.appendChild(pictureEl);
}


            
// Update your click handler setup
function setTimelineImages() {
    // Update selector to get img elements inside picture elements
    timelineImgEls = document.querySelectorAll('.timeline_item-left-img');
    
    timelineImgEls.forEach(el => {
        el.addEventListener('click', () => {      
            swapImage(el).then(() => {
                setTimeout(() => {
                    showPopup(imagePopupEl);
                }, 200)                
            })
        })
    })
}

function removeSubstring(originalText, substringToRemove) {
    // Use regular expression to replace all occurrences of the substring with an empty string
    const newText = originalText.replace(new RegExp(substringToRemove, 'g'), '');

    return newText;
}

function setTimelineData() {
    const timelineContainer = document.querySelector('.about__timeline');
    timelineContainer.innerHTML = `
    ${timelineData.map(el => {
        // Create WebP URL by replacing .jpg with .webp
        const webpUrl = images[el.img].replace('.jpg', '.webp');
        
        return `
        <div class="about__timeline_item">
            <div class="timeline_item-left">
                <picture>
                    <source 
                        srcset="${webpUrl}" 
                        type="image/webp"
                    >
                    <img 
                        src="${images[el.img]}" 
                        class="timeline_item-left-img" 
                        alt="${el.alt}" 
                        loading="lazy"
                        data-image-key="${el.img}"
                    >
                </picture>
                <p class="timeline_item-left-text text">(kliknij, aby powiększyć)</p>
            </div>
            <div class="timeline_item-right">
                <p class="timeline_item-right-title subtitle">
                    <span class="timeline_item-right-year">
                        ${el.date}
                    </span>
                    | ${el.title}
                </p>
                <p class="timeline_item-right-text text">
                    ${el.text}
                </p>
            </div>
        </div>
        `;
    }).join('')}
    `
}
