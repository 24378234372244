const _temp0 = require("url:../img/about/timeline/1-max.jpg");
const _temp1 = require("url:../img/about/timeline/2-max.jpg");
const _temp2 = require("url:../img/about/timeline/3-max.jpg");
const _temp3 = require("url:../img/about/timeline/4-max.jpg");
const _temp4 = require("url:../img/about/timeline/5-max.jpg");
const _temp5 = require("url:../img/about/timeline/6-max.jpg");
const _temp6 = require("url:../img/about/timeline/7-max.jpg");
const _temp7 = require("url:../img/about/timeline/8-max.jpg");
const _temp8 = require("url:../img/about/timeline/9-max.jpg");
const _temp9 = require("url:../img/about/timeline/10-max.jpg");
const _temp10 = require("url:../img/about/timeline/11-max.jpg");
module.exports = {
  "1": _temp0,
  "2": _temp1,
  "3": _temp2,
  "4": _temp3,
  "5": _temp4,
  "6": _temp5,
  "7": _temp6,
  "8": _temp7,
  "9": _temp8,
  "10": _temp9,
  "11": _temp10
}