import { fadeInElement, fadeOutElement, slideInListElements, slideOutListElements, scaleUp, scaleDown, showLoadingMail } from "./visuals.js";
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

export function setNavigation() {
    setVisuals();
    setScrolling();
    setNavbar();
    setMailLoading();
}

let currentSectionIndex = 0;
let isToggled = false;

function setNavbar() {
    const toggleBtnEl = document.getElementById('nav-toggle');
    // Instead of finding an i element, we'll work with the button directly
    toggleBtnEl.addEventListener('click', () => {
        toggleNavbar(toggleBtnEl);
    })
    setVisibility();
    window.addEventListener('resize', () => {
        setVisibility();
    })
}

function setVisibility() {
    if ((window.innerWidth > 1140 && window.innerHeight > 650) && !isToggled) {
        const navMenuLeftEl = document.getElementById('nav__menu-left');
        navMenuLeftEl.classList.remove('hidden');
        navMenuLeftEl.querySelectorAll('li').forEach((li) => {
            li.classList.remove('item-hidden');
            li.classList.remove('invisible');
        })
    } else if(!isToggled) {
        const navMenuLeftEl = document.getElementById('nav__menu-left');
        navMenuLeftEl.classList.add('hidden');
    }
}

function toggleNavbar(toggleBtn) {
    const navEl = document.querySelector('.nav');
    const navMenuLeftEl = document.getElementById('nav__menu-left');
    const navMenuRightEl = document.getElementById('nav__menu-right');
    const logoEl = document.querySelector('.nav__logo');

    // Check if current icon is bars by looking for the SVG path data
    const isBarsIcon = toggleBtn.querySelector('svg path')?.getAttribute('d')?.startsWith('M0 96C0');

    if (isBarsIcon) {
        isToggled = true;
        fadeOutElement(toggleBtn);
        fadeOutElement(navMenuRightEl);
        scaleDown(logoEl);  
        setTimeout(() => {            
            navMenuRightEl.classList.add('hidden');
            // Replace the icon using Font Awesome's icon() function
            toggleBtn.innerHTML = icon(faXmark).html[0];
            navEl.classList.add('nav-toggled');
            fadeInElement(toggleBtn);            
        }, 300)

        setTimeout(() => {
            navMenuLeftEl.classList.remove('hidden')
            slideInListElements(navMenuLeftEl.querySelectorAll('li'), 100);
            scaleUp(logoEl);   
        }, 500)

    } else {
        isToggled = false;
        slideOutListElements(navMenuLeftEl.querySelectorAll('li'), 100);
        fadeOutElement(toggleBtn);
        scaleDown(logoEl);
        setTimeout(() => {            
            // Replace with bars icon
            toggleBtn.innerHTML = icon(faBars).html[0];
            fadeInElement(toggleBtn);     
        }, 300)

        setTimeout(() => {            
            navMenuLeftEl.classList.add('hidden')
            navMenuRightEl.classList.remove('hidden')       
            navEl.classList.remove('nav-toggled'); 
            scaleUp(logoEl);    
            fadeInElement(navMenuRightEl);          
        }, 530)
    }
}


function setVisuals() {
    // SCROLL SECTIONS
    const sectionsEl = document.querySelectorAll('section[id]');
    function changeActiveMenuItem() {
        const scrollY = window.scrollY;
        sectionsEl.forEach(section => {
            const sectionId = section.getAttribute('id');
            const sectionHeight = section.offsetHeight;
            const sectionTop = section.offsetTop - 205;
            const menuLink = document.querySelector(`.nav__menu a[href*=${sectionId}]`);
            if (sectionTop < scrollY && scrollY <= sectionTop + sectionHeight) {
                menuLink.parentElement.classList.add('active')
                setSectionIndex(sectionId);
                if (sectionId != "home") {
                    menuLink.querySelector('.nav__current').classList.remove('hidden');
                }
            } else {
                menuLink.parentElement.classList.remove('active');
                if (sectionId != "home") {
                    menuLink.querySelector('.nav__current').classList.add('hidden');
                }
            }
        })
    };

    function setSectionIndex(sectionId) {
        for (var i = 0; i < sectionsEl.length; i++) {
            if (sectionsEl[i].id === sectionId) {              
                currentSectionIndex = i;
                break;
            }
        }
    }
    window.addEventListener('scroll', changeActiveMenuItem);
}

function setScrolling() {
    // Get all sections on the page
    const sections = document.querySelectorAll("section");

    // Function to scroll to a specific section
    function scrollToSection(index) {
        sections[index].scrollIntoView({ behavior: "smooth"});
    }

    // Function to check if we're at the end of a section

    function shouldScrollDown() {
        const currentTop = document.documentElement.scrollTop;
        const screenHeight = window.innerHeight;
        const currentBottom = currentTop + screenHeight;

        let nextSectionIndex = Math.min(currentSectionIndex + 1, sections.length - 1);
        let nextSectionTop = sections[nextSectionIndex].offsetTop;
        
        if (nextSectionTop < currentBottom - 200 && currentSectionIndex < sections.length - 1) {
            return true;
        } else {
            return false;
        }
    }

    function shouldScrollUp(section) {
        const currentTop = document.documentElement.scrollTop;
        const currentSectionTop = section.offsetTop;    
        if (currentSectionTop > currentTop + 100) {
            return true;
        } else {
            return false;
        }
    }

    let isScrollPaused = false;
    function pauseScroll() {
        isScrollPaused = true;
    }
    function unpauseScroll() {
        isScrollPaused = false;
    }

    setTimeout(() => {
        const home = document.querySelector('.home');
        home.addEventListener('mouseover', pauseScroll);
        home.addEventListener('mouseover', unpauseScroll);
        home.addEventListener('touchstart', pauseScroll, {passive: true});
        home.addEventListener('touchend', unpauseScroll, {passive: true});
    }, 1000)

    setTimeout(() => {
        const news = document.querySelectorAll('.news__card');       
        news.forEach((n) => {
            n.addEventListener('mouseover', pauseScroll);
            n.addEventListener('mouseover', unpauseScroll);
        })
        }, 1000);

    setTimeout(() => {
        const services = document.querySelectorAll('.service');   
    
        services.forEach((s) => {
            s.addEventListener('mouseover', pauseScroll);    
            s.addEventListener('mouseover', unpauseScroll);
        })

        const servicesContainerEl = document.querySelector('.another__container');
        servicesContainerEl.addEventListener('touchstart', pauseScroll, {passive: true});
        servicesContainerEl.addEventListener('touchend', unpauseScroll, {passive: true});
        }, 1000);

    setTimeout(() => {
        const gallery = document.querySelector('.gallery');
        gallery.addEventListener('touchstart', pauseScroll, {passive: true});
        gallery.addEventListener('touchend', unpauseScroll, {passive: true});
    }, 1000)
    
    // Listen for the wheel event (scrolling)
    document.addEventListener("wheel", (event) => {
        const deltaY = event.deltaY; // Scrolling up, or down
        changeNavbarVisibility(deltaY);

        // if (!isScrollPaused) {
        //     if (deltaY > 0 && shouldScrollDown()) {
        //         // Scrolling down
        //         currentSectionIndex++;
        //         scrollToSection(currentSectionIndex);
        //     } else if (deltaY < 0 && currentSectionIndex > 0 && shouldScrollUp(sections[currentSectionIndex])) {
        //         // Scrolling up
        //         currentSectionIndex--;
        //         scrollToSection(currentSectionIndex);
        //     }
        // }
    });
    // let handleScroll = function(e) {
    //     e.preventDefault();
    // }

    // document.addEventListener('scroll', handleScroll, false);
    // document.addEventListener('mousewheel', handleScroll, false);
    // document.addEventListener('wheel', handleScroll, false);


    let prevScrollPos = 0;
    document.addEventListener('touchmove', (e) => {
        const target = e.target;
       
        if (!isScrollPaused) {
        changeNavbarVisibility(prevScrollPos - e.changedTouches[0].clientY);
        prevScrollPos = e.changedTouches[0].clientY;
        }
        // const isSwiperElement = target.closest('.swiper');
        // if (!isSwiperElement) {

        // }
    }, {passive: true});
}

let lastDelta = 0;  
function changeNavbarVisibility(deltaY) {
    const navEl = document.querySelector('.nav');    
    if (window.innerHeight < 700 || window.innerWidth < 800) {
        
        if (deltaY > 0 && !(lastDelta > 0)) {
            lastDelta = deltaY;
            navEl.classList.add('invisible');

        } else if (deltaY < 0 && !(lastDelta < 0)) {
            lastDelta = deltaY;
            navEl.classList.remove('invisible');
        }
    } else if (navEl.classList.contains('invisible')) {
        navEl.classList.remove('invisible');
    }    
}

function setMailLoading() {
    let formEl = document.querySelector('.contact-form');
    formEl.addEventListener('submit', () => {
        showLoadingMail();
    })
}