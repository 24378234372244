const _temp0 = require("url:../img/services/behawioralna.jpg");
const _temp1 = require("url:../img/services/dogfitness.jpg");
const _temp2 = require("url:../img/services/grupowe.jpg");
const _temp3 = require("url:../img/services/indywidualne.jpg");
const _temp4 = require("url:../img/services/nosework.jpg");
const _temp5 = require("url:../img/services/rally-o.jpg");
const _temp6 = require("url:../img/services/spacer.jpg");
const _temp7 = require("url:../img/services/wychowawcza.jpg");
module.exports = {
  "behawioralna": _temp0,
  "dogfitness": _temp1,
  "grupowe": _temp2,
  "indywidualne": _temp3,
  "nosework": _temp4,
  "rally-o": _temp5,
  "spacer": _temp6,
  "wychowawcza": _temp7
}