import { initializeCalendar,loadEvents } from "./calendar";

// Configuration
const CONFIG = {
    GOOGLE_API_KEY: 'AIzaSyC3vVPbC6TixiZ3M3I8FFGWr1FHQ1TOwbU',
    CALENDAR_ID: '0451b5ecd6a21ec1f5ace4d03f028703a1f9d2d146341b5fc2cc1b08c7001790@group.calendar.google.com',
    CALENDAR_ENDPOINT: 'https://www.googleapis.com/calendar/v3/calendars'
};

export function setCalendar() {
    // Initialize basic calendar functionality immediately
    initializeCalendar();
    
    // Load calendar data after page load
    if (document.readyState === 'complete') {
        loadCalendarData();
    } else {
        window.addEventListener('load', loadCalendarData);
    }
}

async function loadCalendarData() {
    try {
        const events = await fetchEventsDirectly();
        if (events) {
            const formattedEvents = events.map(convertToFullCalendarEvent);
            loadEvents(formattedEvents);
        }
    } catch (error) {
        console.error('Error loading calendar data:', error);
    }
}

async function fetchEventsDirectly() {
    const timeMin = new Date().toISOString();
    const params = new URLSearchParams({
        key: CONFIG.GOOGLE_API_KEY,
        timeMin,
        showDeleted: false,
        singleEvents: true,
        orderBy: 'startTime'
    });

    const url = `${CONFIG.CALENDAR_ENDPOINT}/${encodeURIComponent(CONFIG.CALENDAR_ID)}/events?${params}`;
    
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data.items;
    } catch (error) {
        console.error('Error fetching calendar events:', error);
        return null;
    }
}

export function convertToFullCalendarEvent(event) {
    return {
        title: event.summary,
        start: event.start.dateTime || event.start.date,
        availability: event.transparency === 'transparent' ? 'free' : 'busy'
    };
}