import { setNavigation } from "./navigation.js"
import { setServices } from "./services.js";
import { setSwiper } from "./swiper.js";
import { setNews } from "./news.js";
import { setAbout } from "./about.js";
import { showLoading, initializeMap } from "./visuals.js";
import { setCalendar } from "./googleCalendar.js";
import { setIcons } from "./icons.js";




showLoading();
setIcons();


setNavigation();


setAbout();
setNews();
setServices();
setSwiper();

initializeMap();
setCalendar();

