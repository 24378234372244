	[
		  {
			"img": "1",
			"alt": "szczenię owczarka staroniemieckiego",
			"date": "2016",
			"title": "Talar",
			"text": "W marcu odebrałam wraz z tatą małą, czarną puchatą kulkę, która wywróciła nasze rodzinne życie do góry nogami."
		  },
		  {
			"img": "2",
			"alt": "dyplomy ukończenia kursów",
			"date": "2017 - 2018",
			"title": "Pakiet pierwszych kursów",
			"text": "Pierwsze szkolenia dot. pierwszej pomocy przedweterynaryjnej, wychowania szczenięcia, podstawowych problemów behawioralnych oraz życia z psim seniorem."
		  },
		  {
			"img": "3",
			"alt": "certyfikat trenera psów canid",
			"date": "2018",
			"title": "Trener Psów Canid",
			"text": "Kurs organizowany przez Centrum Kynologiczne Canid. Egzamin zdawałam z własnym psem - Talarem. Już wtedy stopniowo wchodziłam w świat treningu z obcymi psami zdobywając swoje pierwsze doświadczenia."
		  },
		  {
			"img": "4",
			"alt": "zaświadczenie o tytule instruktora rally-o",
			"date": "2020",
			"title": "Instruktor Rally-O",
			"text": "Kurs Rally-O organizowany przez Psią Wachtę oraz Olgę Nylec ze Stowarzyszenia Rally-O zdobywając tytuł \"Instruktora Rally-O.\""
		  },
		  {
			"img": "5",
			"alt": "trenerka z owczarkiem staroniemieckim",
			"date": "2020",
			"title": "Otwarcie działalności",
			"text": "Pierwszego września 2020 roku formalnie ogłosiłam się w Internecie oferując pomoc w szkoleniu psiaków bazując tylko na szkoleniu pozytywnym."
		  },
		  {
			"img": "6",
			"alt": "zaświadczenie i certyfikat zoopsycholog / behawiorysta",
			"date": "2020 - 2021",
			"title": "Kurs Behawiorysta / Zoopsycholog spec. psy",
			"text": "W 2020 rozpoczęłam kurs Behawiorysta / Zoopsycholog organizowany przez Polską Akademię Zoopsychologii i Animaloterapii, który zakończyłam z wynikiem pozytywnym początkiem 2021 roku uzyskując tytuł \"Behawiorysta/Zoopsycholog spec. psy\"."
		  },
		  {
			"img": "7",
			"alt": "certyfikat instruktora nosework",
			"date": "2021",
			"title": "Instruktor Nosework",
			"text": "Chcąc nadal rozwijać swoje kompetencje rozpoczęłam kolejny kurs sportowy organizowany przez Fundację Irma oraz Jagnę Gill. W trakcie jego trwania zdobywałam doświadczenie prowadząc dwa zespoły szkoleniowe, które stopniowo musiały zaliczać każdą omawianą kwestię."
		  },
		  {
			"img": "8",
			"alt": "certyfikat z zajęć komunikacji",
			"date": "2022",
			"title": "Intensywna nauka",
			"text": "To był rok najbardziej obfitujący w kursy, seminaria oraz webinary o różnej tematyce. Wzięłam udział w m.in. klasach komunikacji prowadzonych przez PAZiA i Marinę Garfagnolii jako uczestnik z psem (Talar), kursach dot. podstaw dietetyki psów, czy webinarze dot. lęku separacyjnego prowadzonego przez Malenę de Martini."
		  },
		  {
			"img": "9",
			"alt": "toller retriever z nowej szkocji",
			"date": "2022",
			"title": "Muffinka",
			"text": "W grudniu tuż przed Mikołajkami pojawiła się ona - ruda diablica, która dała nową motywację do nauki. To dzięki niej zanurzyłam się jeszcze bardziej w świecie noseworku."
		  },
		  {
			"img": "10",
			"alt": "certyfikat instruktora dogfitness",
			"date": "2023",
			"title": "Instruktor Dogfitness",
			"text": "Mierząc się ze zdrowotnymi problemami moich psiaków podjęłam decyzję, aby dokształcić się w kwestiach zoofizjoterapeutycznych. Na sam start zdecydowałam się, aby zaznajomić się z tematem fitnessu dla psów. Wzięłam udział w warsztatach on-line u Strefa Psiego Fitnessu, po czym następnie zapisałam się na kurs instruktorski PAZiA. Mnóstwo teorii oraz praktycznej pracy z psami i klientem - egzamin zakończyłam z wynikiem pozytywnym. "
		  },
		  {
			"img": "11",
			"alt": "instruktorka groomerstwa i kursantka z dwoma psami: owczarkiem niemieckim oraz retrieverem z nowej szkocji",
			"date": "2024",
			"title": "Grooming",
			"text": "W pierwszym tygodniu września rozpoczęłam podstawowy kurs groomerski. Trwał on dwa tygodnie podczas których uczyłam się pielęgnacji, strzyżenia i trymowania psiaków. Dowiedziałam się również jak prowadzić działalnosć i co jest niezbędne w salonie. Kurs obfitował w pasę praktyki - strzygłam żywych modeli m.in. maltańczyki, yorki, shih tzu, ale też pojawiły się pudle."
		  }
	]