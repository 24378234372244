class Calendar {
    constructor() {
        this.date = new Date();
        this.currentMonth = this.date.getMonth();
        this.currentYear = this.date.getFullYear();
        this.days = ['pon.', 'wt.', 'śr.', 'czw.', 'pt.', 'sob.', 'niedz.'];
        this.events = new Map();
        
        this.initializeCalendar();
        this.setupEventListeners();
    }

    initializeCalendar() {
        const grid = document.getElementById('calendarGrid');
        
        // Add header cells
        this.days.forEach(day => {
            const headerCell = document.createElement('div');
            headerCell.className = 'calendar-header-cell';
            headerCell.textContent = day;
            grid.appendChild(headerCell);
        });

        this.renderMonth();
    }

    getMonthInfo(year, month) {
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        
        // Adjust for Monday as first day of week
        let firstDayIndex = firstDay.getDay() - 1;
        if (firstDayIndex === -1) firstDayIndex = 6;

        return {
            firstDay,
            lastDay,
            firstDayIndex,
            totalDays: lastDay.getDate()
        };
    }

    renderMonth() {
        const grid = document.getElementById('calendarGrid');
        // Clear existing cells except headers
        while (grid.children.length > 7) {
            grid.removeChild(grid.lastChild);
        }
    
        const currentMonthInfo = this.getMonthInfo(this.currentYear, this.currentMonth);
        
        // Calculate previous month details
        let prevMonth = this.currentMonth - 1;
        let prevYear = this.currentYear;
        if (prevMonth < 0) {
            prevMonth = 11;
            prevYear--;
        }
        const prevMonthInfo = this.getMonthInfo(prevYear, prevMonth);
    
        // Calculate next month details
        let nextMonth = this.currentMonth + 1;
        let nextYear = this.currentYear;
        if (nextMonth > 11) {
            nextMonth = 0;
            nextYear++;
        }
    
        // Previous month's days
        for (let i = currentMonthInfo.firstDayIndex - 1; i >= 0; i--) {
            const day = prevMonthInfo.totalDays - i;
            const date = new Date(prevYear, prevMonth, day);
            this.createDayCell(date, true);
        }
    
        // Current month's days
        for (let day = 1; day <= currentMonthInfo.totalDays; day++) {
            const date = new Date(this.currentYear, this.currentMonth, day);
            this.createDayCell(date, false);
        }
    
        // Next month's days
        const remainingCells = 42 - (currentMonthInfo.firstDayIndex + currentMonthInfo.totalDays);
        for (let day = 1; day <= remainingCells; day++) {
            const date = new Date(nextYear, nextMonth, day);
            this.createDayCell(date, true);
        }
    }

    createDayCell(date, isOtherMonth) {
        const cell = document.createElement('div');
        cell.className = `calendar-cell${isOtherMonth ? ' other-month' : ''}`;
        
        const dateNumber = document.createElement('div');
        dateNumber.className = 'date-number';
        dateNumber.textContent = date.getDate();
        cell.appendChild(dateNumber);

        // Add events for this date
        const events = this.getEventsForDate(date);
        events.forEach(event => {
            const eventDiv = document.createElement('div');
            eventDiv.className = `event ${event.availability}`;
            eventDiv.textContent = `${event.title}`;
            eventDiv.addEventListener('click', () => {
                alert(event.title);
            })
            cell.appendChild(eventDiv);
        });

        if (events.length == 0) {
            cell.classList.add('empty');
        }

        const today = new Date();
        if ((date.getDate() == today.getDate()) && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()) {
            cell.classList.add('today');
        }
        document.getElementById('calendarGrid').appendChild(cell);
    }

    getEventsForDate(date) {
        const key = this.formatDateKey(date);
        return this.events.get(key) || [];
    }

    formatDateKey(date) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    setupEventListeners() {
        document.getElementById('prevMonth').addEventListener('click', () => {
            this.currentMonth--;
            if (this.currentMonth < 0) {
                this.currentMonth = 11;
                this.currentYear--;
            }
            this.renderMonth();
            this.updateTitle();
        });

        document.getElementById('nextMonth').addEventListener('click', () => {
            this.currentMonth++;
            if (this.currentMonth > 11) {
                this.currentMonth = 0;
                this.currentYear++;
            }
            this.renderMonth();
            this.updateTitle();
        });

        document.getElementById('today').addEventListener('click', () => {
            const today = new Date();
            this.currentMonth = today.getMonth();
            this.currentYear = today.getFullYear();
            this.renderMonth();
            this.updateTitle();
        });
    }

    updateTitle() {
        const months = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 
                      'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];
        document.querySelector('.calendar-title').textContent = 
            `${months[this.currentMonth]} ${this.currentYear}`;
    }

    addEvent(date, event) {
        const key = this.formatDateKey(date);
        if (!this.events.has(key)) {
            this.events.set(key, []);
        }
        const eventsList = this.events.get(key);
        eventsList.push(event);
        // Sort events by title since they start with time
        eventsList.sort((a, b) => a.title.localeCompare(b.title));
        this.renderMonth();
    }
}

export function loadEvents(events) {
    events.forEach((event) => {
        calendar.addEvent(convertDate(event.start), {title: event.title, availability: event.availability});
    })

    function convertDate(date) {
        const eventDate = date; 
        const [year, month, day] = eventDate.split('-'); 
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }

}

let calendar;
export function initializeCalendar() {
    calendar = new Calendar();
}