// Cache animation durations to avoid recalculation
const ANIMATION_DURATIONS = {
    DEFAULT_TRANSITION: 1000,
    SLIDE_DELAY: 300,
    PULSE_DELAY: 450,
    SCALE_ANIMATION: 490,
    SCALE_RESET: 10,
    MAP_DELAY: 5000
};

function millisecondsToSeconds(ms) {
    const seconds = (ms / 1000).toFixed(1);
    return `${seconds}s`;
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function fadeInElement(element, transitionTime) {
    if (!element) return;
    
    transitionTime = transitionTime || ANIMATION_DURATIONS.DEFAULT_TRANSITION;
    const tempTransition = element.style.transition;
    
    requestAnimationFrame(() => {
        element.style.transition = `${millisecondsToSeconds(transitionTime)} all`;
        element.classList.remove('invisible');
        
        setTimeout(() => {
            element.style.transition = tempTransition;
        }, transitionTime);
    });
}

export function fadeOutElement(element, transitionTime) {
    if (!element) return;
    
    transitionTime = transitionTime || ANIMATION_DURATIONS.DEFAULT_TRANSITION;
    const tempTransition = element.style.transition;
    
    requestAnimationFrame(() => {
        element.style.transition = `${millisecondsToSeconds(transitionTime)} all`;
        element.classList.add('invisible');
        
        setTimeout(() => {
            element.style.transition = tempTransition;
        }, transitionTime);
    });
}

export function slideInListElements(list, delay) {
    if (!list?.length) return;
    
    delay = delay ?? ANIMATION_DURATIONS.SLIDE_DELAY;
    const animationDuration = millisecondsToSeconds(delay + 300);
    
    list.forEach((el, index) => {
        if (!el) return;
        
        el.classList.add('invisible');   
        el.classList.remove('item-hidden');
        
        setTimeout(() => {
            requestAnimationFrame(() => {
                el.classList.remove('invisible');      
                el.style.animationDuration = animationDuration;
                el.classList.add('item-shown');
                
                const img = el.querySelector('img');
                if (img) {
                    setTimeout(() => {
                        img.classList.add('pulsing');
                    }, ANIMATION_DURATIONS.PULSE_DELAY);
                }
            });
        }, delay * index);
    });
}

export function slideOutListElements(list, delay) {
    if (!list?.length) return;
    
    delay = delay ?? ANIMATION_DURATIONS.SLIDE_DELAY;
    const animationDuration = millisecondsToSeconds(delay + 300);
    
    let iteration = 0;
    for (let i = list.length - 1; i >= 0; i--) {
        const element = list[i];
        if (!element) continue;
        
        element.classList.remove('item-shown');
        
        setTimeout(() => {
            requestAnimationFrame(() => {
                element.style.animationDuration = animationDuration;      
                element.classList.add('item-hidden');
                
                setTimeout(() => {
                    element.classList.add('invisible');
                }, delay);
            });
        }, delay * iteration);
        
        iteration++;
    }
}

export function scaleDown(element) {
    if (!element) return;

    setTimeout(() => {
        requestAnimationFrame(() => {
            element.classList.add('scalingDown');
        });
    }, 1);
   
    setTimeout(() => {
        element.style.opacity = 0;
        setTimeout(() => {
            element.classList.remove('scalingDown');
        }, ANIMATION_DURATIONS.SCALE_RESET);        
    }, ANIMATION_DURATIONS.SCALE_ANIMATION);
}

export function scaleUp(element) {
    if (!element) return;

    setTimeout(() => {
        requestAnimationFrame(() => {
            element.classList.add('scalingUp');
        });
    }, 1);
  
    setTimeout(() => {
        element.style.opacity = 1;
        setTimeout(() => {
            element.classList.remove('scalingUp');
        }, ANIMATION_DURATIONS.SCALE_RESET);        
    }, ANIMATION_DURATIONS.SCALE_ANIMATION);
}

export function pulseElements(elements) {
    // Preserved empty function
}

export function showLoading() {
    const blurryBackgroundEl = document.querySelector('.blurry__background-strong');
    const loadingEl = document.querySelector('.loading');
    
    if (!blurryBackgroundEl || !loadingEl) return;

    function hideElements() {
        blurryBackgroundEl.style.display = "none";
        loadingEl.style.display = "none";
    }

    window.addEventListener('DOMContentLoaded', function() {
        window.addEventListener('load', function() {
            requestAnimationFrame(() => {
                blurryBackgroundEl.style.opacity = 0;
                loadingEl.style.opacity = 0;
                setTimeout(hideElements, 1500);
            });
        });
    });
}

export function showLoadingMail() {
    const blurryBackgroundEl = document.querySelector('.blurry__background-strong');
    const loadingEl = document.querySelector('.loading');
    const loadingTextEl = document.querySelector('.loading__text');
    loadingTextEl.innerHTML = "Proszę czekać, trwa wysyłanie maila..."
    
    blurryBackgroundEl.style.display = "block";
    loadingEl.style.position = "fixed";
    loadingEl.style.display = "flex";
    blurryBackgroundEl.style.opacity = 1;
    loadingEl.style.opacity = 1;
}


const observerOptions = {
    root: null,
    rootMargin: '50px',
    threshold: 0.1
};

// Main map initialization function
export function initializeMap() {
    // Wait for DOM to be fully loaded
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', setupMap);
    } else {
        setupMap();
    }
}

function setupMap() {
    const map = document.querySelector('.contact__map');
    if (!map) return;

    // Create intersection observer
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                loadMap(map);
                observer.unobserve(entry.target);
            }
        });
    }, observerOptions);

    // Start observing the map container
    observer.observe(map);
}

function loadMap(mapContainer) {
    const tempImg = mapContainer.querySelector('.contact__map-img');
    if (!tempImg) return;

    // Create iframe with all necessary attributes
    const frame = document.createElement('iframe');
    frame.classList.add('gmap_iframe');
    frame.title = 'mapa google';
    frame.style.cssText = `
        width: 100%;
        height: 100%;
        border: none;
    `;
    
    // Set all attributes in one go
    Object.assign(frame, {
        loading: 'lazy',
        scrolling: 'no',
        marginHeight: '0',
        marginWidth: '0'
    });

    // Add event listener to remove the temporary image only after iframe loads
    frame.addEventListener('load', () => {
        tempImg.remove();
    });

    // Set the source last to trigger loading
    frame.src = mapContainer.getAttribute('data-src');

    // Append the iframe
    requestAnimationFrame(() => {
        mapContainer.appendChild(frame);
    });
}